export const list = [
    'Авторизация / Аутентификация пользователей;',
    'Создание / Редактирование учетных данных пользователей;',
    'Добавление / Изменение ролей пользователей;',
    'Мониторинг работоспособности и функционирование системы;',
    'Выявление / Пресечение попыток несанкционнированого доступа к ресурсам медиааналитической системы;',
    'Управление доступом к программному интерфейсу медианалитической системы;',
    'Контроль и управление аналитическими задания пользователей.',
]

export const linkList = [
    {name: 'Руководство по установке', href: '/docs/ams/АА - Руководство по установке.pdf'},
    {name: 'Руководство по эксплуатации', href: '/docs/ams/АА - Руководство по эксплуатации.pdf'},
    {name: 'Функциональные характеристики', href: '/docs/ams/АА - Функциональные характеристики.pdf'},
    {name: 'Поддержание жизненного цикла, сопровождения и разработки ПО', href: '/docs/ams/АА - Поддержание жизненного цикла, сопровождения и разработки ПО.pdf'}
]