import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from "./App";

const initialState = {
    value: false,
    setValue: () => {}
};

export const ContactsModalContext = React.createContext(initialState);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App></App>
);
