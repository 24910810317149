import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContentContainer from '../../components/ContentContainer';
import AMSContent from '../../components/AMSContent/index.jsx';

function AMSPage() {
    return (
        <div>
            <Header/>
            <ContentContainer>
                <AMSContent/>
            </ContentContainer>
            <Footer/>
        </div>
    );
}

export default AMSPage;