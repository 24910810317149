export const list = [
    'Авторизация/Аутентификация пользователей;',
    'Создание/Редактирование учетных данных пользователей;',
    'Добавление/Изменение ролей пользователей;',
    'Мониторинг работоспособности и функционирование системы;',
    'Выявление/Пресечение попыток несанкционнированого доступа к ресурсам медиааналитической системы;',
    'Управление доступом к программному интерфейсу медианалитической системы;',
    'Контроль и управление аналитическими задания пользователей.',
]

export const linkList = [
    {name: 'Руководство по установке', href: '/docs/dsms/БД - Руководство по установке.pdf'},
    {name: 'Руководство по эксплуатации', href: '/docs/dsms/БД - Руководство по эксплуатации.pdf'},
    {name: 'Функциональные характеристики', href: '/docs/dsms/БД - Функциональные характеристики.pdf'},
    {name: 'Поддержание жизненного цикла, сопровождения и разработки ПО', href: '/docs/dsms/БД - Поддержание жизненного цикла, сопровождения и разработки ПО.pdf'}
]