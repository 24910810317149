import React from "react";
import ProductCard from "../ProductCard";
import styled from "styled-components";
import BrainIcon from './imgs/brain.png';
import CoinsIcon from './imgs/coins.png';
import LockIcon from './imgs/lock.png';
import SendBox from "../SendBox/SendBox";

const cardData = [
    {
        text: 'UGS MC - AMS',
        src: '/product/ams',
        img: LockIcon,
    },
    {
        text: 'UGS MC - DSMS',
        src: '/product/dsms',
        img: CoinsIcon,
    },
    {
        text: 'UGS MC - СМА',
        src: '/product/cma',
        img: BrainIcon,
    },
]

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding-left: 37px;
  padding-top: 37px;
  padding-right: 37px;
`;

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SendBoxContainer = styled.div`
    margin-top: 100px;
`;

const ProductCards = () => {
    return (
        <Container>
            <CardsContainer>
                {cardData.map((item, index) => (
                    <ProductCard key={index} item={item}/>
                ))}
            </CardsContainer>
            <SendBoxContainer>
                <SendBox content={'Стоимость определяется индивидуально или в зависимости от конфигурации, ' +
                    'для уточнения стоимости вы можете направить нам запрос через форму обратной связи.'}/>
            </SendBoxContainer>
        </Container>
    )
}

export default ProductCards;