import {ReactComponent as CardLogo} from "../ProductCards/CardLogo.svg";
import {Link} from "react-router-dom";
import styled from "styled-components";
import React from 'react';

// const StyledCard = styled.div`
// width: 246px;
// height: 444px;
// background: #FBFAFA;
// box-shadow: 1px 5px 20px 5px rgba(0, 0, 0, 0.1);
// border-radius: 15px;
// position: relative;
// margin-right: 80px;
// display: flex;
// flex-direction: column;
// `;
//
// const StyledCardHeaderSpan = styled.span`
// margin-left: 20px;
// font-family: 'Montserrat';
// font-style: normal;
// font-weight: 500;
// font-size: 18px;
// line-height: 22px;
// color: #000000;
// text-decoration: none;
// `;
//
// const LinkWrapper = styled.span`
//
// `;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 376px;
  height: 300px;

  border: 21px solid #E8F1F9;
  border-radius: 59px;
  box-sizing: border-box;
  
  margin-right: 40px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 58px;
`;

const LogoText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;

  margin-left: 7px;

  color: #000000;
`;

const ButtonLink = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 23px 39px;

  width: 201px;
  height: 70px;

  background: #E8F1F9;
  border-radius: 20px;
  border-style: none;
  
  cursor: pointer;
`;

const ButtonText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #129FD4;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProductCard = ({item}) => {
    return (
        <MainContainer>
            <Container>
                <RowContainer>
                    <img src={item.img} width={'64px'}></img>
                </RowContainer>
                <div style={{marginBottom: '32px'}}></div>
                <RowContainer>
                    <LogoContainer>
                        <CardLogo/>
                        <LogoText>
                            {item.text}
                        </LogoText>
                    </LogoContainer>
                </RowContainer>
            </Container>
            <div style={{marginBottom: '32px'}}></div>
            <Link to={item.src} style={{ textDecoration: 'none', marginRight: '40px' }}>
                <ButtonLink>
                    <ButtonText>Подробнее</ButtonText>
                </ButtonLink>
            </Link>
        </MainContainer>
        // <Link to={item.src} style={{ textDecoration: 'none' }}>
        //
        //     {/*<StyledCard>*/}
        //     {/*    <div*/}
        //     {/*        style={{*/}
        //     {/*            width: "100%",*/}
        //     {/*            height: 80,*/}
        //     {/*            display: "flex",*/}
        //     {/*            alignItems: "center",*/}
        //     {/*            justifyContent: "center",*/}
        //     {/*            fontFamily: "Montserrat",*/}
        //     {/*        }}*/}
        //     {/*    >*/}
        //     {/*        <CardLogo />*/}
        //     {/*        <StyledCardHeaderSpan>{item.text}</StyledCardHeaderSpan>*/}
        //     {/*    </div>*/}
        //     {/*    <div*/}
        //     {/*        style={{*/}
        //     {/*            display: "flex",*/}
        //     {/*            justifyContent: "center",*/}
        //     {/*            flexGrow: 3,*/}
        //     {/*            alignItems: "center",*/}
        //     {/*        }}*/}
        //     {/*    >*/}
        //     {/*        <img src={item.img}></img>*/}
        //     {/*    </div>*/}
        //     {/*    <div*/}
        //     {/*        style={{*/}
        //     {/*            width: "100%",*/}
        //     {/*            height: 48,*/}
        //     {/*            background: "#000000",*/}
        //     {/*            borderRadius: "0px 0px 15px 15px",*/}
        //     {/*            color: " #FFFFFF",*/}
        //     {/*            display: "flex",*/}
        //     {/*            alignItems: "center",*/}
        //     {/*            justifyContent: "center",*/}
        //     {/*            fontFamily: "Montserrat",*/}
        //     {/*        }}*/}
        //     {/*    >*/}
        //     {/*        Подробнее*/}
        //     {/*    </div>*/}
        //     {/*</StyledCard >*/}
        // </Link>
    )
}

export default ProductCard;