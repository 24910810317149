import React from "react";
import { ReactComponent as CardLogo } from './CardLogo.svg';
import styled from "styled-components";
import { list, linkList } from "./list";
import Modal from "../Modal";
import SendBox from "../SendBox/SendBox";

// const DSMSContentStyled = styled.div`
//     display: flex;
//     flex-direction: column;
//     font-family: 'Montserrat', sans-serif;
//     text-align: start;
//     //max-width: 800px;
// `;
//
// const HeaderText = styled.div`
//   font-family: 'Montserrat', sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 22px;
//   line-height: 22px;
//
//   color: #000000;
// `;
//
// const ContentContainer = styled.div`
//   max-width: 1100px;
// `;
//
// const BolderContentText = styled.span`
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: bolder;
//   font-size: 16px;
//   line-height: 15px;
//
//   color: #000000;
// `;
//
// const NormalText = styled.p`
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-size: 16px;
//     line-height: 15px;
//
//   color: #000000;
// `
//
// const NormalTextLink = styled.a`
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-size: 16px;
//     line-height: 15px;
//
//   color: #000000;
// `

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 32px;
  margin-top: 37px;
  margin-top: 37px;
  padding-right: 37px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 58px;

  border-color: #D6DDE8;
  border-width: 6px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
`;

const LogoText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;

  margin-left: 7px;

  color: #000000;
`;

const HeaderTitle = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  height: 58px;
`;

const HeaderText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #262626;
`;

const HeaderSubTextBox = styled.div`
  margin-left: 165px;
  padding: 0;
  margin-top: 32px;
`;

const NormalText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #262626;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

const NormalTextLink = styled.a`
  margin-bottom: 12px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #129FD4;
  text-decoration: none;
`;

const SendBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
  
  margin-top: 10px;
`;

const DSMSContent = () => {
    return (
        <Container>
            <HeaderContainer>
                <LogoContainer>
                    <CardLogo/>
                    <LogoText>
                        DSMS
                    </LogoText>
                </LogoContainer>
                <HeaderTitle>
                    <HeaderText style={{marginRight: '12px', marginLeft: '12px'}}> - </HeaderText>
                    <HeaderText>
                        <div style={{marginTop: '32px'}}>
                            Специализированная граф-ориентированная система хранения медиааналитических данных
                        </div>
                    </HeaderText>
                </HeaderTitle>
            </HeaderContainer>
            <HeaderSubTextBox>
                <NormalText>
                    предназначена для сбора и хранения, в структурированном виде, данных медиааналитики с учетом выявленных
                    связей между медиаматериалами, авторами, реакциями аудитории на медиаматериалы, комментариями
                    и действиями аудитории в отношении медиаматериалов, с которыми они ознакомились.
                </NormalText>
            </HeaderSubTextBox>
            <ContentContainer>
                <NormalText>
                    Программа включает в себя парсеры - специальные инструменты для сбора и систематизации информации информации, собранной с различных источников.
                </NormalText>
                <div style={{marginBottom: '16px'}}></div>
                <NormalText>
                    Программа содержит специализированные алгоритмы для обеспечения эффективного поиска по связям в указанной БД и работе с семантическим аспектом медиааналитической информации.
                </NormalText>
                <div style={{marginBottom: '24px'}}></div>
                <NormalText>
                    Дополнительная документация:
                </NormalText>
                <div style={{marginBottom: '16px'}}></div>
                {
                    linkList.map((item, index) => (
                        <NormalTextLink key={index} href={item.href} target="_blank" download>{item.name}</NormalTextLink>
                    ))
                }
            </ContentContainer>
            <SendBoxContainer>
                <SendBox content={'Стоимость программного обеспечения ' +
                    '«Специализированная граф-ориентированная система хранения медиааналитических данных» рассчитывается индивидуально.' +
                    ' Информацию о стоимости Вы можете получить, направив нам запрос через форму обратной связи.'}/>
            </SendBoxContainer>
        </Container>

        // <DSMSContentStyled>
        //     <div style={{ display: 'inline-flex' }}>
        //         <div
        //             style={{
        //                 marginRight: 23,
        //                 width: 25,
        //                 height: 29
        //             }}>
        //             <CardLogo />
        //         </div>
        //         <ContentContainer>
        //             <HeaderText>
        //                 UGS MC - Специализированная граф-ориентированная система хранения медиааналитических данных
        //             </HeaderText>
        //             <NormalText>
        //                 <BolderContentText>«Специализированная граф-ориентированная система хранения медиааналитических данных» </BolderContentText>
        //                 <>
        //                     -  предназначена для сбора и хранения, в структурированном виде, данных медиааналитики с учетом выявленных связей между медиаматериалами, авторами, реакциями аудитории на медиаматериалы, комментариями и действиями аудитории в отношении медиаматериалов, с которыми они ознакомились.
        //                 </>
        //             </NormalText>
        //             <NormalText>
        //                 Программа включает в себя парсеры - специальные инструменты для сбора и систематизации информации информации, собранной с различных источников.
        //             </NormalText>
        //             <NormalText>
        //                 Программа включает в себя парсеры - специальные инструменты для сбора и систематизации информации информации, собранной с различных источников.
        //             </NormalText>
        //             <NormalText>
        //                 Дополнительная документация:
        //             </NormalText>
        //             <ul
        //                 style={{
        //                     paddingLeft: 25,
        //                     marginBottom: 45
        //                 }}
        //             >
        //                 {linkList.map((item) => (
        //                     <li style={{ marginBottom: 7, color: "#2D5BFF", listStyle: 'disc'}}>
        //                         <NormalTextLink style={{ color: "#2D5BFF" }} href="#">{item}</NormalTextLink>
        //                     </li>
        //                 ))}
        //             </ul>
        //             <Modal
        //                 style={{ float: "right" }}
        //                 buttonText={'Узнать стоимость'}
        //                 modalHeader='Свяжитесь с нами'
        //                 inputButton='Узнать стоимость'
        //             />
        //         </ContentContainer>
        //     </div>
        // </DSMSContentStyled>
    )
}

export default DSMSContent;