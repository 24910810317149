import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import MainPage from "./pages/MainPage";
import ProductPage from "./pages/ProductPage";
import AMSPage from "./pages/AMSPage";
import CMAPage from "./pages/CMAPage";
import DSMSPage from "./pages/DSMSPage";
import React, {useState} from "react";
import {ContactsModalContext} from "./index";

function App() {

    const [value, setValue] = useState(false);

    return (
        <ContactsModalContext.Provider value={{value, setValue}}>
            <BrowserRouter>
                <Routes>
                    <Route path="/*" element={<Navigate to="/main"/>}/>
                    <Route path="/main" element={<MainPage/>}/>
                    <Route path="/product" element={<ProductPage/>}/>
                    <Route path="/product/" element={<ProductPage/>}/>
                    <Route path="/product/ams" element={<AMSPage/>}/>
                    <Route path="/product/cma" element={<CMAPage/>}/>
                    <Route path="/product/dsms" element={<DSMSPage/>}/>
                    {/*<Route path="/contacts" element={<ContactPage/>}/>*/}
                    {/*<Route path="/beta" element={<BetaPage/>} />*/}
                </Routes>
            </BrowserRouter>
        </ContactsModalContext.Provider>
  );
}

export default App;
