import React from "react";
import styled from "styled-components";

const WrapperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: row;

  width: 1286px;
  height: 790px;
  margin-top: 40px;

  background: #FFFFFF;
  border-radius: 20px;
`

const ContentContainer = (props) => {
    return (
        <WrapperContainer>
            <StyledContentContainer>
                {props.children}
            </StyledContentContainer>
        </WrapperContainer>
    )
}

export default ContentContainer;