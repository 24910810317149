import React from "react";
import { ReactComponent as CardLogo } from './CardLogo.svg';
import styled from "styled-components";
import { list, linkList } from "./list";
import SendBox from "../SendBox/SendBox";

// const CMAContentStyled = styled.div`
//     display: flex;
//     flex-direction: column;
//     font-family: 'Montserrat';
//     text-align: start;
// `
//
// const HeaderText = styled.div`
//   font-family: 'Montserrat', sans-serif;
//   font-style: normal;
//   font-weight: 500;
//   font-size: 22px;
//   line-height: 22px;
//
//   color: #000000;
// `;
//
// const ContentContainer = styled.div`
//   max-width: 1100px;
// `;
//
// const BolderContentText = styled.span`
//   font-family: 'Montserrat';
//   font-style: normal;
//   font-weight: bolder;
//   font-size: 16px;
//   line-height: 15px;
//
//   color: #000000;
// `;
//
// const NormalText = styled.p`
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-size: 16px;
//     line-height: 15px;
//
//   color: #000000;
// `
//
// const NormalTextLink = styled.a`
//     font-family: 'Montserrat';
//     font-style: normal;
//     font-size: 16px;
//     line-height: 15px;
//
//   color: #000000;
// `

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 32px;
  margin-top: 37px;
  padding-right: 37px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 58px;

  border-color: #D6DDE8;
  border-width: 6px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
`;

const LogoText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;

  margin-left: 7px;

  color: #000000;
`;

const HeaderTitle = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  height: 58px;
`;

const HeaderText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #262626;
`;

const HeaderSubTextBox = styled.div`
  margin-left: 145px;
  padding: 0;
  margin-top: 32px;
`;

const NormalText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #262626;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

const TextRowBox = styled.span`
  display: flex;
  margin-bottom: 4px;
`;

const ListMarkerBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListMarker = styled.span`
  width: 8px;
  height: 8px;
  background: #787E93;
  opacity: 0.8;
  border-radius: 4px;

  margin-right: 16px;
`;

const NormalTextLink = styled.a`
  margin-bottom: 8px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #129FD4;
  text-decoration: none;
`;

const SendBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
  
  margin-top: 10px;
`;

const CMAContent = () => {
    return (
        <Container>
            <HeaderContainer>
                <LogoContainer>
                    <CardLogo/>
                    <LogoText>
                        CMA
                    </LogoText>
                </LogoContainer>
                <HeaderTitle>
                    <HeaderText style={{marginRight: '12px', marginLeft: '12px'}}> - </HeaderText>
                    <HeaderText>
                        <div style={{marginTop: '32px'}}>
                            Программа для систематизации медиааналитических данных для контроля и оценки информационной повестки – “Кластер”
                        </div>
                    </HeaderText>
                </HeaderTitle>
            </HeaderContainer>
            <HeaderSubTextBox>
                <NormalText>
                    предназначена для работы с неструктурированными данными, возникающими в процессе медиамониторинга
                    интернет пространства, обогащения этих данных, выявления закономерностей наступления
                    информационных событий, подготовки данных для визуализации и осуществления визуализации сформированных данных.
                </NormalText>
            </HeaderSubTextBox>
            <ContentContainer>
                <NormalText>
                    Программа представляет из себя полноценное рабочее место  для специалистов по управлению медиамониторингом,
                    медиакампаниями и анализу информационного поля, таких как:
                </NormalText>
                <div style={{marginBottom: '12px'}}></div>
                {
                    list.map((item, index) => (
                        <TextRowBox key={index}>
                            <ListMarkerBox>
                                <ListMarker/>
                            </ListMarkerBox>
                            <NormalText>
                                {item}
                            </NormalText>
                        </TextRowBox>
                    ))
                }
                <div style={{marginBottom: '15px'}}></div>
                <NormalText>
                    Данный продукт совместим со всеми известными ОС семейства Linux, включая ОС включенные в
                    единый реестр российских программ для электронных вычислительных машин и баз данных.
                </NormalText>
                <div style={{marginBottom: '15px'}}></div>
                <NormalText>
                    Дополнительная документация:
                </NormalText>
                <div style={{marginBottom: '10px'}}></div>
                {
                    linkList.map((item, index) => (
                        <NormalTextLink key={index} href={item.href} target="_blank" download>{item.name}</NormalTextLink>
                    ))
                }
            </ContentContainer>
            <SendBoxContainer>
                <SendBox content={'Стоимость программного обеспечения ' +
                    '«Программа для систематизации медиааналитических данных для контроля и оценки информационной повестки – “Кластер”» рассчитывается индивидуально.' +
                    ' Информацию о стоимости Вы можете получить, направив нам запрос через форму обратной связи.'}/>
            </SendBoxContainer>
        </Container>
    )
}

export default CMAContent;