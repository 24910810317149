import React from "react";
import styled from 'styled-components';

const style = {
    display: 'inline-flex',
    backgroundColor: "#000000",
    alignItems: 'center',
    color: '#FFFFFF',
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "35px",
    width: "100vw"
};

const StyledFooter = styled.div`
    width: 100vw;
    height: 35px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    background-color: #000000;
    position: fixed;
    left: 0px;
    bottom: 0px;
    padding:20px;
    box-sizing: border-box;
`;

function Footer() {
    return (
        <StyledFooter >
            <span>© 2022 Решение</span>
            <span>support@resheniy.ru</span>
            {/* <span>Сведения о cookies-файлах</span> */}
        </StyledFooter>
    );
}

export default Footer;