import React from "react";
import {ReactComponent as CardLogo} from './CardLogo.svg';
import styled from "styled-components";
import {list, linkList} from "./list";
import SendBox from "../SendBox/SendBox";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 32px;
  margin-top: 37px;
  
  padding-right: 37px;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 58px;

  border-color: #D6DDE8;
  border-width: 6px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
`;

const LogoText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;

  margin-left: 7px;

  color: #000000;
`;

const HeaderTitle = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;

  height: 58px;
`;

const HeaderText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;

  color: #262626;
`;

const HeaderSubTextBox = styled.div`
  margin-left: 140px;
  margin-top: -8px;
  padding: 0;
`;

const NormalText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #262626;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

const TextRowBox = styled.span`
  display: flex;
  margin-bottom: 8px;
`;

const ListMarkerBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ListMarker = styled.span`
  width: 8px;
  height: 8px;
  background: #787E93;
  opacity: 0.8;
  border-radius: 4px;

  margin-right: 16px;
`;

const NormalTextLink = styled.a`
  margin-bottom: 12px;
  
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #129FD4;
  text-decoration: none;
`;

const SendBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  width: 100%;
  height: 100%;
  
  margin-top: 10px;
`;

const AMSContent = () => {
    return (
        <Container>
            <HeaderContainer>
                <LogoContainer>
                    <CardLogo/>
                    <LogoText>
                        AMS
                    </LogoText>
                </LogoContainer>
                <HeaderTitle>
                    <HeaderText style={{marginRight: '12px', marginLeft: '12px'}}> - </HeaderText>
                    <HeaderText>
                        Модуль авторизации и аутентификации для медиааналитических систем
                    </HeaderText>
                </HeaderTitle>
            </HeaderContainer>
            <HeaderSubTextBox>
                <NormalText>
                    представляет из себя сервис позволящий управлять доступом к медиааналитической платформе
                    на основании технологии single sign-on.
                </NormalText>
            </HeaderSubTextBox>
            <ContentContainer>
                <NormalText>
                    Основные задачи, решаемые, администраторами системы, с помощью данного продукта включают в себя:
                </NormalText>
                <div style={{marginBottom: '16px'}}></div>
                {
                    list.map((item, index) => (
                        <TextRowBox key={index}>
                            <ListMarkerBox>
                                <ListMarker/>
                            </ListMarkerBox>
                            <NormalText>
                                {item}
                            </NormalText>
                        </TextRowBox>
                    ))
                }
                <div style={{marginBottom: '24px'}}></div>
                <NormalText>
                    Данный продукт совместим со всеми известными ОС семейства Linux, включая ОС включенные в
                    единый реестр российских программ для электронных вычислительных машин и баз данных.
                </NormalText>
                <div style={{marginBottom: '24px'}}></div>
                <NormalText>
                    Дополнительная документация:
                </NormalText>
                <div style={{marginBottom: '16px'}}></div>
                {
                    linkList.map((item, index) => (
                        <NormalTextLink key={index} href={item.href} target="_blank" download>{item.name}</NormalTextLink>
                    ))
                }
            </ContentContainer>
            {/*        <Modal*/}
            {/*            style={{float: "right"}}*/}
            {/*            buttonText={'Узнать стоимость'}*/}
            {/*            modalHeader='Свяжитесь с нами'*/}
            {/*            inputButton='Узнать стоимость'*/}
            {/*        />*/}
            <SendBoxContainer>
                <SendBox content={'Стоимость программного обеспечения ' +
                    '«Модуль авторизации и аутентификации для медиааналитических систем» рассчитывается индивидуально.' +
                    ' Информацию о стоимости Вы можете получить, направив нам запрос через форму обратной связи.'}/>
            </SendBoxContainer>
        </Container>
    )
}

export default AMSContent;