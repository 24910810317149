import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContentContainer from '../../components/ContentContainer';
import DSMSContent from '../../components/DSMSContent/index.jsx';

function DSMSPage() {
    return (
        <div>
            <Header />
            <ContentContainer>
                <div style={{
                    display: "inline-flex"
                }}>
                    <DSMSContent/>
                </div>
            </ContentContainer>
            <Footer />
        </div>
    );
}

export default DSMSPage;