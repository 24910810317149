import React from "react";
import NavigationPanel from "../NavigationPanel";
import styled from 'styled-components';
import {Link} from "react-router-dom";
import LogoIcon from './imgs/logo.svg';
import LogoTextIcon from './imgs/logoText.svg';

// const StyledHeader = styled.div`
//   width: 100vw;
//   min-width: 700px;
//   height: 70px;
//   display: inline-flex;
//   align-items: center;
//   box-sizing: border-box;
//   padding-left: 40px;
//   padding-top: 20px
// `;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  height: 110px;

  background-color: white;
`;

const LogoContainer = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  width: 40px;
  height: 20px;

  margin-right: 16px;
`;

const LogoTextContainer = styled.img`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const LinkContainer = styled.span`
  margin: 50px 100px 50px 29px;
`;

const LinkContentStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Header = () => {
    return (
        <StyledHeader>
            <LinkContainer>
                <Link to={'/main'}>
                    {/*<LogoContainer src={LogoIcon} alt="logo"/>*/}
                    {/*<LogoTextContainer src={LogoTextIcon} alt="logo text"></LogoTextContainer>*/}
                    <LinkContentStyle>
                        <LogoContainer src={LogoIcon} alt="logo"/>
                        <LogoTextContainer src={LogoTextIcon} alt="logo text"></LogoTextContainer>
                    </LinkContentStyle>
                </Link>
            </LinkContainer>
            <NavigationPanel/>
        </StyledHeader>
    )
}

export default Header