import Modal from "../Modal";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 980px;
  height: 90px;
  
  margin-bottom: 90px;

  border: solid 1px #129FD4;
  border-radius: 20px;
  box-sizing: border-box;
`;

const Title = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  
  width: 100%;
  height: 100%;

  color: #000000;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const DescriptionText = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  
  text-align: center;
  
  padding: 5px;
  width: 695px;
  height: 100%;

  color: #000000;
`

const SendBox = ({ content }) => {
    return (
        <Container>
            <TextContainer>
                <Title>Заказать аудит информационного материала</Title>
                <DescriptionText>{content}</DescriptionText>
            </TextContainer>

            <Modal
                style={{
                    selfAlign: 'right',
                }}
                buttonIsVisible={true}
                buttonText='Отправить заявку'
                modalHeader="Заказать бесплатный аудит"
                inputButton='Отправить заявку'
            />
        </Container>
    )
}

export default SendBox;