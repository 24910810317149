import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Modal from '../../components/Modal';
import styled from 'styled-components';
import {useEffect, useState} from "react";
import OpenSendForm from "../../components/OpenSendForm/index";

const StyledMainText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 96px;
  line-height: 117px;

  width: 1000px;

  margin-bottom: 40px;

  color: #000000;
`

const VideoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const OrangeBlock = styled.div`
  width: 383px;
  height: 383px;
  background: #FE6645;
  border-radius: 50%;
  margin-left: -260px;
  z-index: -1;
`;

const BlueBlock = styled.div`
  width: 383px;
  height: 383px;
  background: #2D5BFF;
  border-radius: 50%;
  z-index: -2;
`;

const VideoBlock = styled.div`
  border-radius: 50%;
  width: 383px;
  height: 383px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  margin-left: -260px;
`;

const StyledVideo = styled.video`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  width: 383px;
  height: 383px;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
`

const StyledModal = styled.div`
  width: 400px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 250px;
  margin-top: 120px;
`;

const OpenFormContainer = styled.div`
  width: 400px;
  height: 170px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-left: 500px;
  margin-top: 210px;
`;

const ModalTitle = styled.div`
  width: 354px;

  margin-bottom: 24px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;

  color: #000000;
`;

const ModalSubTitle = styled.div`
  margin-bottom: 24px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #787E93;
`;

const Container = styled.div`
  display: flex;
  margin-left: 78px;
  margin-top: 91px;
  overflow: hidden;
`;

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
}

function MainPage() {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // console.log(`with: ${windowDimensions.width}, height: ${windowDimensions.height}`);

    return (
        // сверстать без overflow
        <div style={{overflowX: 'hidden'}}>
            <Header/>
            <Container>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start'
                }}>
                    <StyledMainText>
                        Машины, которые понимают медиа
                    </StyledMainText>
                    <div style={{
                        display: 'flex'
                    }}>
                        <VideoContainer>
                            <BlueBlock/>
                            <OrangeBlock/>
                            <VideoBlock>
                                <StyledVideo
                                    muted='muted'
                                    autoplay='autoplay'
                                    loop='loop'
                                    poster='poster-gif.gif'
                                >
                                    <source src='./covid.webm' type='video/webm'/>
                                </StyledVideo>
                            </VideoBlock>
                        </VideoContainer>
                        {
                            windowDimensions.width > 1400
                                ? (
                                    <OpenFormContainer>
                                        <ModalTitle>
                                            Заказать аудит информационного материала
                                        </ModalTitle>
                                        <ModalSubTitle>
                                            (Telegram, VK.ru, ОК.ru)
                                        </ModalSubTitle>
                                        <OpenSendForm inputButton='Отправить заявку'/>
                                    </OpenFormContainer>
                                )
                                : (
                                    <StyledModal>
                                        <ModalTitle>
                                            Заказать аудит информационного материала
                                        </ModalTitle>
                                        <ModalSubTitle>
                                            (Telegram, VK.ru, ОК.ru)
                                        </ModalSubTitle>
                                        <Modal
                                            style={{
                                                selfAlign: 'right',
                                            }}
                                            buttonIsVisible={true}
                                            buttonText='Отправить заявку'
                                            modalHeader="Заказать бесплатный аудит"
                                            inputButton='Отправить заявку'
                                        />
                                    </StyledModal>
                                )
                        }
                    </div>
                </div>
            </Container>
            <Footer></Footer>
        </div>
    );
}

export default MainPage;