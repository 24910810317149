import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContentContainer from '../../components/ContentContainer';
import ProductCards from "../../components/ProductCards";
import Modal from "../../components/Modal";

function ProductPage() {
    return (
        <div>
            <Header/>
            <ContentContainer>
                <ProductCards/>
            </ContentContainer>
            <Footer></Footer>
        </div>
    );
}

export default ProductPage;