import React from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import ContentContainer from '../../components/ContentContainer';
import CMAContent from "../../components/CMAContent";

function CMAPage() {
    return (
        <div>
            <Header></Header>
            <ContentContainer>
                <div style={{
                    display: "inline-flex"
                }}>
                    <CMAContent/>
                </div>
            </ContentContainer>
            <Footer></Footer>
        </div>
    );
}

export default CMAPage;