import React, {useEffect, useState} from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";

const StyledModalOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 313px;
  height: 300px;

  background: #FFFFFF;

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
`;

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 23px;
`;

const StyledInputsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 265px;
  justify-content: space-around;
`;

const StyledInput = styled.input`
  height: 30px;

  margin-bottom: 12px;
  
  border: none;
  border-bottom: 1px solid #129FD4;

  outline: 0;
  outline-offset: 0;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`;

const StyledInputButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 265px;
  height: 70px;

  margin-top: 20px;
  margin-bottom: 32px;

  background: #129FD4;
  border-radius: 20px;
  border-style: none;

  cursor: pointer;
`;

const ButtonText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;
`;

const MessageBox = styled.div`
  width: 354px;
  height: 24px;

  margin-top: 10px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
`;

const SuccessMessage = styled.span`
  color: #00CC99;
`;

const ErrorMessage = styled.span`
  color: #F36869;
`;

const OpenSendForm = (props) => {
    const [sendEmail, setSendEmail] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [isError, setErrorSend] = useState(false);
    const [isSuccess, setSuccessSend] = useState(false);
    const [isProcess, setProcessSend] = useState(false);

    function clear() {
        setUserName('');
        setUserEmail('');
        setUserPhone('');
        setErrorSend(false);
        setProcessSend(false);
    }

    function onSending(){
        setErrorSend(false);
        setSuccessSend(false);
        setProcessSend(true);
    }

    function onError() {
        setErrorSend(true);
        setSuccessSend(false);
        setProcessSend(false);
    }

    function onSuccess() {
        setErrorSend(false);
        setSuccessSend(true);
        setProcessSend(false);
    }

    function onSendModal() {
        if (!userEmail.length > 0) {
            onError();
            return;
        }
        setSendEmail(true);
        onSending();
    }

    function handleChangeName(e) {
        let pureStr = e.target.value.replace(/[&\/\\#,+()@$~%.'":&*?<>{}]/g, '');
        pureStr = pureStr.substring(0, 150);
        setUserName(pureStr);
    }

    function handleChangeEmail(e) {
        let pureStr = e.target.value.replace(/[&\/\\,'":<>{}]/g, '');
        pureStr = pureStr.substring(0, 150);
        setUserEmail(pureStr);
    }

    function handleChangePhone(e) {
        let pureStr = e.target.value.replace(/[^0-9,.]/g, ' ');
        pureStr = pureStr.substring(0, 20);
        setUserPhone(pureStr);
    }


    useEffect(() => {
        if (sendEmail) {
            const templateParams = {
                userName: userName,
                userEmail: userEmail,
                userPhone: userPhone
            };
            emailjs.send('service_mti3vmz', 'template_14kys1n', templateParams, 'HscZMxpT5kqLeSV4I')
                .then(function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                    onSuccess();
                    clear();
                }, function (err) {
                    console.log('FAILED...', err);
                });
            setSendEmail(false);
        }
    }, [sendEmail, userName])

    return (
        <StyledModalOverlay>
                <StyledModalContent>
                    <form>
                        <StyledInputsDiv>
                            <StyledInput
                                name="name"
                                type="text"
                                placeholder="Имя"
                                onChange={handleChangeName}
                            />
                            <StyledInput
                                name="email"
                                type="email"
                                placeholder="Email"
                                onChange={handleChangeEmail}
                            />
                            <StyledInput
                                name="name"
                                type="tel"
                                placeholder="Телефон"
                                onChange={handleChangePhone}
                            />
                        </StyledInputsDiv>
                    </form>
                    <MessageBox>
                        {
                            (isError && <ErrorMessage>Пожалуйста, заполните Email</ErrorMessage>)
                        }
                        {
                            (isSuccess && <SuccessMessage>Заявка отправлена</SuccessMessage>)
                        }
                        {
                            (isProcess && <SuccessMessage>Отправка...</SuccessMessage>)
                        }
                    </MessageBox>
                    <StyledInputButton onClick={onSendModal}>
                        <ButtonText>
                            {props.inputButton}
                        </ButtonText>
                    </StyledInputButton>
                </StyledModalContent>
        </StyledModalOverlay>
    );

}
export default OpenSendForm;