import React, {useEffect} from "react";
import {useState} from "react";
import styled from "styled-components";
import {ReactComponent as CloseButton} from './closeModalButton.svg';
import {useContext} from "react";
import {ContactsModalContext} from "../../index";
import emailjs from 'emailjs-com';

const StyledModalContainer = styled.div`
`
const StyledModalOverlay = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 999;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  background: rgba(29, 29, 27, 0.5);
`

const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 10px;
`

const StyledModalWindow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 462px;
  height: 352px;

  margin-top: 200px;

  background: #FFFFFF;

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;


  z-index: 2000;
  //position: absolute;
  //left: 50%;
  //top: 50%;
  //transform: translate(-50%, -50%);

  opacity: 1;
`

const StyledInputsDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 398px;
  justify-content: space-around;
`

const StyledInput = styled.input`
  height: 30px;

  margin-bottom: 15px;


  border: none;
  border-bottom: 1px solid #129FD4;

  outline: 0;
  outline-offset: 0;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #000000;
`

const StyledInputButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 398px;
  height: 70px;

  margin-top: 25px;
  margin-bottom: 32px;

  background: #129FD4;
  border-radius: 20px;
  border-style: none;

  cursor: pointer;

  //&:disabled{
  //  border: 1px solid #999999;
  //  background-color: #cccccc;
  //  color: #666666;
  //}
`

const ButtonText = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;
`


const StyledButton = styled.button`
  display: flex;
  padding: 8px 16px;
  border: none;
  justify-content: center;
  align-items: center;

  width: 200px;
  height: 40px;


  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  text-align: center;

  color: #000000;


  background: #D9D9D9;

  cursor: pointer;
`

const ButtonContainer = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 270px;
  height: 90px;

  padding: 23px 39px;
  border-radius: 20px;
  border: none;

  background-color: #129FD4;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #FFFFFF;

  cursor: pointer;
`;

const CloseButtonContainer = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;

  width: 404px;
  margin-top: 20px;
  cursor: pointer;
`;

const MessageBox = styled.div`
  width: 354px;
  height: 24px;

  margin-top: 10px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`;

const SuccessMessage = styled.span`
  color: #00CC99;
`;

const ErrorMessage = styled.span`
  color: #F36869;
`;


const Modal = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [sendEmail, setSendEmail] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [isError, setErrorSend] = useState(false);
    const [isSuccess, setSuccessSend] = useState(false);
    const [isProcess, setProcessSend] = useState(false);
    const modalContext = useContext(ContactsModalContext);

    useEffect(() => {
        if (modalContext.value) {
            setIsOpen(true);
        }
    }, [modalContext.value])

    function clear() {
        setUserName('');
        setUserEmail('');
        setUserPhone('');
        setErrorSend(false);
        setSuccessSend(false);
        setProcessSend(false);
    }

    function closeModal() {
        setIsOpen(!isOpen);
        if (modalContext.value) {
            modalContext.setValue(false);
        }
        clear();
    }

    function onSending(){
        setErrorSend(false);
        setSuccessSend(false);
        setProcessSend(true);
    }

    function onError() {
        setErrorSend(true);
        setSuccessSend(false);
        setProcessSend(false);
    }

    function onSuccess() {
        setErrorSend(false);
        setSuccessSend(true);
        setProcessSend(false);
    }

    function onSendModal() {
        if (!userEmail.length > 0) {
            onError();
            return;
        }
        setSendEmail(true);
        onSending();
    }

    function toggleModal() {
        closeModal();
    }

    function handleChangeName(e) {
        let pureStr = e.target.value.replace(/[&\/\\#,+()@$~%.'":&*?<>{}]/g, '');
        pureStr = pureStr.substring(0, 150);
        setUserName(pureStr);
    }

    function handleChangeEmail(e) {
        let pureStr = e.target.value.replace(/[&\/\\,'":<>{}]/g, '');
        pureStr = pureStr.substring(0, 150);
        setUserEmail(pureStr);
    }

    function handleChangePhone(e) {
        let pureStr = e.target.value.replace(/[^0-9,.]/g, ' ');
        pureStr = pureStr.substring(0, 20);
        setUserPhone(pureStr);
    }

    useEffect(() => {
        if (sendEmail) {
            const templateParams = {
                userName: userName,
                userEmail: userEmail,
                userPhone: userPhone
            };
            emailjs.send('service_mti3vmz', 'template_14kys1n', templateParams, 'HscZMxpT5kqLeSV4I')
                .then(function (response) {
                    console.log('SUCCESS!', response.status, response.text);
                    onSuccess();
                }, function (err) {
                    console.log('FAILED...', err);
                });
            setSendEmail(false);
        }
    }, [sendEmail, userName])

    return (
        <StyledModalContainer style={props.style}>
            {
                props.buttonIsVisible && (
                    <ButtonContainer onClick={toggleModal}>{props.buttonText}</ButtonContainer>
                )
            }
            {(isOpen || modalContext.value) && (
                <StyledModalOverlay>
                    <StyledModalWindow>
                        <CloseButtonContainer>
                            <CloseButton
                                onClick={toggleModal}
                                // style={{
                                //     cursor: 'pointer',
                                //     position: "absolute",
                                //     top: 30,
                                //     right: 28,
                                // }}
                            />
                        </CloseButtonContainer>

                        {/*<h4 style={{textAlign: "center", maxWidth: 300}}>{props.modalHeader}</h4>*/}
                        <StyledModalContent>
                            <form>
                                <StyledInputsDiv>
                                    <StyledInput
                                        name="name"
                                        type="text"
                                        placeholder="Имя"
                                        onChange={handleChangeName}
                                    />
                                    <StyledInput
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        onChange={handleChangeEmail}
                                    />
                                    <StyledInput
                                        name="name"
                                        type="tel"
                                        placeholder="Телефон"
                                        onChange={handleChangePhone}
                                    />
                                </StyledInputsDiv>
                            </form>
                            <MessageBox>
                                {
                                    (isError && <ErrorMessage>Пожалуйста, заполните Email</ErrorMessage>)
                                }
                                {
                                    (isSuccess && <SuccessMessage>Заявка отправлена</SuccessMessage>)
                                }
                                {
                                    (isProcess && <SuccessMessage>Отправка...</SuccessMessage>)
                                }
                            </MessageBox>
                            <StyledInputButton onClick={onSendModal}>
                                <ButtonText>
                                    {props.inputButton}
                                </ButtonText>
                            </StyledInputButton>
                        </StyledModalContent>
                    </StyledModalWindow>
                </StyledModalOverlay>
            )}
        </StyledModalContainer>
    )
}

export default Modal