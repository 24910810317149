import React, {useContext} from "react";
import {Link, useLocation} from "react-router-dom";
import styled from 'styled-components';
import {
    HeaderNavigationStore
} from '../../store/store';
import {useState as useStateHook} from '@hookstate/core';
import {ContactsModalContext} from "../../index";


const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
`;

const ActiveLinkBox = styled.div`
  min-height: 28px;
  max-width: 108px;

  margin-right: 52px;

  border-color: #FE6645;
  border-width: 1px;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;

  box-sizing: border-box;
`;

const SimpleLinkBox = styled.div`
  min-height: 28px;
  max-width: 108px;

  margin-right: 52px;
`;

const ActiveLink = styled(Link)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #FE6645;

  cursor: pointer;

  text-decoration: none;
`;

const SimpleLink = styled(Link)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #129FD4;

  cursor: pointer;

  text-decoration: none;
`;

const PseudoLink = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #129FD4;

  cursor: pointer;

  text-decoration: none;
`;

const StyledLink = styled(Link)`
  margin-right: 52px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 15px;

  color: ${props => props.activeLink || "#000000"};
  text-decoration: none;

  padding-bottom: 3px;

  ${props => props.activeLink?.length && 'border-bottom: #129FD4 solid 1px'};

  text-align: start;

  &:hover {
    color: #129FD4;
    cursor: pointer;
  }
`;

const NavigationPanel = () => {
    const modalContext = useContext(ContactsModalContext);
    const HeaderNavigation = useStateHook(HeaderNavigationStore);
    const location = useLocation();


    const navOptions = [
        {
            navText: 'Продукты',
            src: '/product',
        },
        // {
        //     navText: 'Компания',
        //     src: '/',
        // },
        // {
        //     navText: 'Карьера',
        //     src: '/',
        // },
        // {
        //     navText: 'Контакты',
        //     src: '/contacts',
        // },
        // {
        //     navText: 'Бета Версия',
        //     src: '/beta',
        // },
    ];

    const handleLinkClick = (activeLink) => {
        if (activeLink === 1) {
            modalContext.setValue(true);
        }
        HeaderNavigation.set(activeLink)
    }

    let activeLinkIndex = -1;

    switch (location.pathname) {
        case '/main':
            activeLinkIndex = -1;
            break;
        case '/product':
        case '/product/ams':
        case '/product/dsms':
        case '/product/cma':
            activeLinkIndex = 0;
            break;
        default:
            activeLinkIndex = -1;
    }

    return (
        <StyledContainer>
            {/*{navOptions.map((item, index) => (*/}
            {/*    <span key={index}>*/}
            {/*        {*/}
            {/*            (index === activeLinkIndex)*/}
            {/*                ? (<ActiveLinkBox>*/}
            {/*                        <ActiveLink*/}
            {/*                            onClick={() => handleLinkClick(index)}*/}
            {/*                            to={item.src}*/}
            {/*                        >*/}
            {/*                            {item.navText}*/}
            {/*                        </ActiveLink>*/}
            {/*                    </ActiveLinkBox>*/}
            {/*                )*/}
            {/*                : (<SimpleLinkBox>*/}
            {/*                        <SimpleLink*/}
            {/*                            onClick={() => handleLinkClick(index)}*/}
            {/*                            to={item.src}*/}
            {/*                            // activeLink={index === HeaderNavigation.get() && '#129FD4'}*/}
            {/*                        >*/}
            {/*                            {item.navText}*/}
            {/*                        </SimpleLink>*/}
            {/*                    </SimpleLinkBox>*/}
            {/*                )*/}
            {/*        }*/}
            {/*    </span>*/}
            {/*))}*/}
            <span>
                {
                    (activeLinkIndex === 0)
                        ? (<ActiveLinkBox>
                                <ActiveLink
                                    onClick={() => handleLinkClick(0)}
                                    to={'/product'}
                                >
                                    Продукты
                                </ActiveLink>
                            </ActiveLinkBox>
                        )
                        : (<SimpleLinkBox>
                                <SimpleLink
                                    onClick={() => handleLinkClick(0)}
                                    to={'/product'}
                                >
                                    Продукты
                                </SimpleLink>
                            </SimpleLinkBox>
                        )
                }
            </span>
            <span>
                <SimpleLinkBox>
                    <PseudoLink onClick={() => handleLinkClick(1)}>
                        Контакты
                    </PseudoLink>
                 </SimpleLinkBox>
            </span>
        </StyledContainer>
    )
}

export default NavigationPanel;