export const list = [
    'Медиааналитик;',
    'Медиаменеджер;',
    'Старший медиаменеджер;',
    'Менеджер по клиентам (продажам);',
    'Скаут (менеджер по работе с блогерами и медиа);',
    'Контролер;',
    'Стратегический медиааналитик.',
]

export const linkList = [
    {name: 'Руководство по установке', href: '/docs/cma/ML - Руководство по установке.pdf'},
    {name: 'Руководство по эксплуатации', href: '/docs/cma/ML - Руководство по эксплуатации.pdf'},
    {name: 'Функциональные характеристики', href: '/docs/cma/ML - Функциональные характеристики.pdf'},
    {name: 'Поддержание жизненного цикла, сопровождения и разработки ПО', href: '/docs/cma/ML - Поддержание жизненного цикла, сопровождения и разработки ПО.pdf'}
]